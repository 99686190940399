<script setup lang="ts">
const { itemsInCart } = await useCart()

const menuOpen = ref(false)
</script>

<template>
  <header>
    <div class="bg-neutral-900 py-2 text-sm text-white">
      <div class="container flex items-center justify-between">
        <UiButtonPhone />
        <div class="flex gap-5">
          <NuxtLink
            external
            to="https://zakaznik.grapesc.cz/prihlaseni"
            class="flex items-center gap-2"
          >
            <div
              class="flex size-5 items-center justify-center rounded-full bg-white"
            >
              <Icon
                name="fa6-solid:right-to-bracket"
                class="size-3 text-neutral-900"
              />
            </div>
            <div class="max-md:hidden">zákaznická zóna</div>
          </NuxtLink>
          <NuxtLink
            external
            to="https://www.grapesc.cz/webmail"
            class="flex items-center gap-2"
          >
            <div
              class="flex size-5 items-center justify-center rounded-full bg-white"
            >
              <Icon name="fa-solid:at" class="size-3 text-neutral-900" />
            </div>
            <div class="max-md:hidden">přihlášení do emailu</div>
          </NuxtLink>
          <div class="flex gap-2">
            <NuxtLink
              external
              target="_blank"
              to="https://www.facebook.com/grapescas.cz"
              class="flex size-5 items-center justify-center rounded-full bg-white"
            >
              <Icon
                name="fa-brands:facebook-f"
                class="size-3 text-neutral-900"
              />
            </NuxtLink>
            <NuxtLink
              external
              target="_blank"
              to="https://www.instagram.com/grape_sc_as"
              class="flex size-5 items-center justify-center rounded-full bg-white"
            >
              <Icon
                name="fa-brands:instagram"
                class="size-3 text-neutral-900"
              />
            </NuxtLink>
            <NuxtLink
              external
              target="_blank"
              to="https://twitter.com/grape_sc"
              class="flex size-5 items-center justify-center rounded-full bg-white"
            >
              <Icon name="fa-brands:twitter" class="size-3 text-neutral-900" />
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4">
      <div class="container relative flex items-center justify-between gap-5">
        <NuxtLink to="/">
          <img class="max-h-8 w-full" src="/img/logo.svg" />
        </NuxtLink>
        <button class="lg:hidden" @click="menuOpen = !menuOpen">
          <Icon name="material-symbols:menu" class="size-6"></Icon>
        </button>
        <div
          :class="menuOpen ? 'block' : 'max-lg:hidden'"
          class="right-0 top-12 z-50 flex items-center gap-2 max-xl:text-sm max-lg:absolute max-lg:flex-col max-lg:rounded-b-2xl max-lg:bg-neutral-50 max-lg:p-5"
        >
          <NuxtLink
            active-class="bg-red-600 text-white py-0.5 rounded-full"
            class="px-4"
            to="/internet"
          >
            INTERNET
          </NuxtLink>
          <span class="h-[16px] w-[2px] max-lg:bg-transparent max-lg:h-[5px] bg-black"></span>
          <NuxtLink
            active-class="bg-red-600 text-white py-0.5 rounded-full"
            class="px-4"
            to="/televize"
          >
            TELEVIZE
          </NuxtLink>
          <span class="h-[16px] w-[2px] max-lg:bg-transparent max-lg:h-[5px] bg-black"></span>
          <NuxtLink
            active-class="bg-red-600 text-white py-0.5 rounded-full"
            class="px-4"
            to="/mobil"
          >
            MOBIL
          </NuxtLink>
          <span class="h-[16px] w-[2px] max-lg:bg-transparent max-lg:h-[5px] bg-black"></span>
          <NuxtLink
            active-class="bg-red-600 text-white py-0.5 rounded-full"
            class="px-4"
            to="/sluzby"
          >
            SLUŽBY
          </NuxtLink>
          <span class="h-[16px] w-[2px] max-lg:bg-transparent max-lg:h-[5px] bg-black"></span>
          <NuxtLink
            active-class="bg-red-600 text-white py-0.5 rounded-full"
            class="px-4"
            to="/dotazy"
          >
            DOTAZY
          </NuxtLink>
          <span class="h-[16px] w-[2px] max-lg:bg-transparent max-lg:h-[5px] bg-black"></span>
          <NuxtLink
            active-class="bg-red-600 text-white py-0.5 rounded-full"
            class="px-4"
            to="/kontakty"
          >
            KONTAKTY
          </NuxtLink>
          <span class="h-[16px] w-[2px] max-lg:bg-transparent max-lg:h-[5px] bg-black"></span>
          <NuxtLink
            active-class="bg-red-600 text-white py-0.5 rounded-full [&>span]:bg-white [&>span]:text-red-600"
            class="flex items-center justify-center gap-2 px-4"
            to="/kosik"
          >
            KOŠÍK
            <span
              class="flex items-center justify-center rounded-full bg-red-600 px-2 text-sm text-white"
            >
              {{ itemsInCart }}
            </span>
          </NuxtLink>
        </div>
      </div>
    </div>
  </header>
</template>
