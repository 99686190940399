<script setup lang="ts"></script>

<template>
  <div class="flex min-h-screen flex-col">
    <UiHeader />
    <div>
      <slot />
    </div>
    <UiFooter class="mt-auto" />
  </div>
</template>
