<script setup lang="ts"></script>

<template>
  <footer class="container py-10 text-center text-xs leading-loose">
    <p>{{ new Date().getFullYear() }} © GRAPE SC, a. s.</p>
    <div>
      <NuxtLink to="/pdf/vop.pdf" target="_blank" external>
        Všeobecné obchodní podmínky
      </NuxtLink>
      |
      <NuxtLink to="/pdf/gphone.pdf" target="_blank" external>
        Všeobecné obchodní podmínky - G.Phone
      </NuxtLink>
      |
      <NuxtLink to="/pdf/vop-mobilnidata.pdf" target="_blank" external>
        Podmínky služby Mobilní datové připojení
      </NuxtLink>
      |
      <NuxtLink to="/pdf/vzor_smlouvy.pdf" target="_blank" external>
        Vzor smlouvy
      </NuxtLink>
      |
      <NuxtLink to="/pdf/vzor_specifikace_sluzby.pdf" target="_blank" external>
        Vzor specifikace služby
      </NuxtLink>
    </div>
    <div>
      <NuxtLink to="/pdf/cenik2021.pdf" target="_blank" external>
        Ceník služeb
      </NuxtLink>
      |
      <NuxtLink
        to="/pdf/Oznameni_o_typech_rozhrani_pro_pripojeni_k_verejne_komunikacni_siti.pdf"
        target="_blank"
        external
      >
        Oznámení o typech rozhraní
      </NuxtLink>
      |
      <NuxtLink
        to="/pdf/Technicke_parametry_sluzeb.pdf"
        target="_blank"
        external
      >
        Technické parametry služeb
      </NuxtLink>
      |
      <NuxtLink to="/tabulka-rychlosti">Tabulka rychlostí</NuxtLink>
      |
      <NuxtLink to="/pdf/pausalni_nahrada.pdf" target="_blank" external>
        Paušální náhrady
      </NuxtLink>
      |
      <NuxtLink to="/darkove-vouchery">Dárkové vouchery</NuxtLink>
      |
      <NuxtLink
        to="/pdf/souhlas-s-umistenim-zarizeni.pdf"
        target="_blank"
        external
      >
        Souhlas s umístěním zařízení
      </NuxtLink>
    </div>
    <div>
      <NuxtLink to="/ochrana-osobnich-udaju">Ochrana osobních údajů</NuxtLink>
    </div>
    <NuxtLink
      external
      to="https://www.jagu.cz"
      target="_blank"
      class="mx-auto flex w-fit items-center justify-center gap-1"
    >
      Vytvořeno Jagu
      <svg
        class="h-auto w-6"
        xmlns="http://www.w3.org/2000/svg"
        width="723.517"
        height="547.367"
        viewBox="0 0 723.517 547.367"
      >
        <path
          id="icon"
          d="M761.232,161.245c-8.345-12.518-25.036-17.287-41.429-18.777-25.931,15.5-45.006,56.034-53.948,83.753,0,1.192-14.307,68.552-34.872,45.9-9.24-20.268-7.451-34.872-12.22-74.513-3.875-29.507-17.883-43.218-30.4-49.775h0c-9.538-9.538-169.592-47.39-181.812,37.555-17.287-22.056-40.237-44.41-62.591-66.764C322.2,96.866,304.616,93.289,291.5,95.674c3.577-38.449,3.577-70.34-4.173-78.388-.6-.3-11.922-11.028-29.209-4.173-23.546,10.73-48.285,32.786-65.274.3-1.192-4.471-40.535-37.555-45.3,20.566C150.82,62.59,158.57,189.858,158.57,189.858c.3,53.649-73.321-7.451-78.09-8.047C36.07,168.1,41.435,199.4,41.435,216.087c0,7.153,5.067,13.114,13.412,20.268,8.942,4.173,23.248,11.922,29.209,27.123,6.855,16.989,70.34,50.669,78.09,53.65,14.307,5.663,25.931,8.644,35.468,9.538,3.279,14.307,8.345,27.719,13.114,36.064,13.114,22.95,91.2-3.279,120.413-3.279s45.6,22.652,58.716,25.931c3.577.894,9.836-1.49,16.691-5.067l2.682,1.788c39.045,19.672,101.04-25.931,101.04-25.931s-6.557,126.97-25.931,123.692c-19.672-3.279-45.6-55.438-68.552-22.95s-13.114,48.881,6.557,58.716S510.272,567.789,536.5,535.3c25.931-32.488,65.273-94.483,68.552-133.528.6-8.047.3-23.248-.6-42.323,40.833,16.393,80.772,22.652,92.1,13.71,15.8-13.114,84.349-182.706,64.677-211.916"
          transform="translate(-41.232 -0.001)"
          fill="#f89815"
        />
      </svg>
    </NuxtLink>
  </footer>
</template>
